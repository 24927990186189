<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-12">
        <post-selling-archive-datatable/>
      </div>
    </div>
  </div>
</template>

<script>

import PostSellingArchiveDatatable from "@/pages/PostSelling/datatables/PostSellingArchiveDatatable.vue";
export default {
  name: "IndexPostSellingArchivePage",
  components: {PostSellingArchiveDatatable},
}
</script>

<style scoped lang="scss">

</style>
