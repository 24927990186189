<template>
  <octo-table
      show-filters
      :action="endpoints.DATATABLES.postSellings"
      :fields="fields"
      :filters="filters"
      enable-export
      enable-export-email
      :name="name"
      :order-by-items="['id', 'date_start', 'date_in', 'date_out', 'confirmed_date']"
      @onResetFilters="filters = {}"
  >
    <template v-slot:status="data">
      <badge  v-if="data.row.status" :style="{backgroundColor: schedulerService.getDealStatusColor(data.row.status)}" class="text-uppercase">
        {{ $t('deal.status.' + data.row.status) }}
      </badge>
    </template>

    <template v-slot:accounting_status="data">
      <badge :type="dealAccountingStatuses[data.row.accounting_status].color" class="text-uppercase">
        {{ $t('deal.accounting_statuses.' + data.row.accounting_status) }}
      </badge>
    </template>

    <template v-slot:registry="data">
      {{ data.row.registry | optional('surname') }}
      {{ data.row.registry | optional('name') }}
    </template>

    <template v-slot:sales="data">
      <div class="small">
        {{ users[data.row.user_id] | optional('lastname') }}
        {{ users[data.row.user_id] | optional('firstname') }}
      </div>
    </template>

    <template v-slot:operator="data">
      <div class="small">
        {{ users[data.row.lead.user_id] | optional('lastname') }}
        {{ users[data.row.lead.user_id] | optional('firstname') }}
      </div>
    </template>

    <template v-slot:date="data">
      <div class="small">
        {{ data.row.date_in | date }} <span v-if="data.row.date_out">- {{ data.row.date_out | date }}</span>
      </div>
    </template>

    <template v-slot:amount="data">
      <div v-if="data.row.dealExtraInfo && data.row.dealExtraInfo.contract_amount">{{ data.row.dealExtraInfo.contract_amount | euro }}</div>
    </template>

    <template v-slot:confirmed_date="data">
      <div class="small">
        {{ data.row | optional('dealExtraInfo.confirmed_date') | date }}
      </div>
    </template>

    <template v-slot:payment_type="data">
      <div class="small text-uppercase text-truncate" v-if="data.row.dealExtraInfo && data.row.dealExtraInfo.payment_type">
        {{ $t('deal.payment_types.' + data.row.dealExtraInfo.payment_type ) }}
      </div>
    </template>

    <template v-slot:competence_date="data">
      <div class="small">
        {{ data.row.competence_date | date }}
      </div>
    </template>

    <template v-slot:actions="data">
      <div class="d-flex justify-content-end">
        <base-button class="mx-1" link size="sm" icon
                     @click="$router.push({name: 'post_selling.show', params: {id: data.row.id}})">
          <octo-icon icon="right-arrow"/>
        </base-button>
      </div>
    </template>

    <template slot="custom-filters">
      <div class="col-12">
        <base-input :label="$t('datatable.courses')">
          <el-select
              multiple
              filterable
              class="select-default text-uppercase"
              :placeholder="$t('common.type_to_search')"
              v-model="filters.courseIds"
          >
            <el-option
                v-for="course in courses"
                class="select-default text-uppercase"
                :key="course.id"
                :value="course.id"
                :label="$options.filters.optional(course, 'title')"
            />
          </el-select>
        </base-input>
      </div>
      <div class="col-12 col-md-4">
        <base-input
            :label="$t('datatable.phone')"
            v-model="filters.phone"
        />
      </div>
      <div class="col-12 col-md-4">
        <base-input
            :label="$t('datatable.email')"
            v-model="filters.email"
        />
      </div>
      <div class="col-12 col-md-4">
        <base-input
          :label="$t('deal.holder')"
          v-model="filters.holder"
        />
      </div>

      <div class="col-12 col-md-6">
        <base-input :label="$t('datatable.sales')">
          <el-select
              multiple
              filterable
              class="select-default text-uppercase"
              :placeholder="$t('common.type_to_search')"
              v-model="filters.salesIds"
          >
            <el-option
                v-for="user in sales"
                class="select-default text-uppercase"
                :value="user.id"
                :key="user.id"
                :label="`${$options.filters.optional(user, 'firstname')} ` +
                      `${$options.filters.optional(user, 'lastname')}`"
            >
            </el-option>
          </el-select>
        </base-input>
      </div>
      <div class="col-12 col-md-6">
        <base-input :label="$t('datatable.operators')">
          <el-select
              multiple
              filterable
              class="select-default text-uppercase"
              :placeholder="$t('common.type_to_search')"
              v-model="filters.operatorIds"
          >
            <el-option
                v-for="user in operators"
                class="select-default text-uppercase"
                :value="user.id"
                :key="user.id"
                :label="`${$options.filters.optional(user, 'firstname')} ` +
                      `${$options.filters.optional(user, 'lastname')}`"
            >
            </el-option>
          </el-select>
        </base-input>
      </div>
      <div class="col-12 col-md-4">
        <base-input :label="$t('datatable.payment_type')">
          <el-select
            multiple
            filterable
            class="select-default text-uppercase"
            :placeholder="$t('common.type_to_search')"
            v-model="filters.paymentTypes"
          >
            <el-option
              v-for="(option, index) in paymentOptions"
              v-bind:key="`payment-type-${index}`"
              class="select-default text-uppercase"
              :value="option"
              :label="$t('deal.payment_types.' + option)"
            >
            </el-option>
          </el-select>
        </base-input>
      </div>
      <div class="col-12 col-md-4">
        <base-input :label="$t('datatable.status')">
          <el-select
              multiple
              filterable
              class="select-default text-uppercase"
              :placeholder="$t('common.type_to_search')"
              v-model="filters.statuses"
          >
            <el-option
                v-for="status in statuses"
                class="select-default text-uppercase"
                :value="status"
                :label="$t('deal.status.' + status)"
                :key="status"
            />
          </el-select>
        </base-input>
      </div>
      <div class="col-12 col-md-4">
        <base-input :label="$t('datatable.accounting_status')">
          <el-select
              multiple
              filterable
              class="select-default text-uppercase"
              :placeholder="$t('common.type_to_search')"
              v-model="filters.accountingStatuses"
          >
            <el-option
                v-for="status in dealAccountingStatuses"
                class="select-default text-uppercase"
                :value="status.value"
                :label="$t('deal.accounting_statuses.' + status.value)"
                :key="status.value"
            />
          </el-select>
        </base-input>
      </div>
      <div class="col-12 col-md-4">
        <base-input :label="$t('common.special_filter')">
          <el-select
              clearable
              class="select-default text-uppercase"
              :placeholder="$t('fields.choose')"
              v-model="filters.special"
          >
            <el-option
                v-for="option in specialOptions"
                class="select-default text-uppercase"
                :value="option.value"
                :label="option.label"
                :key="option.value"
            >
            </el-option>
          </el-select>
        </base-input>
      </div>
      <div class="col-12 col-md-4">
        <base-input :label="$t('fields.date_in')">
          <el-date-picker
              v-model="filters.dateInRange"
              type="daterange"
              align="right"
              unlink-panels
              format="dd/MM/yyyy"
              value-format="yyyy-MM-dd"
              range-separator="-"
              :start-placeholder="$t('fields.date_start')"
              :end-placeholder="$t('fields.date_end')"
              :picker-options="pickerOptions"
          />
        </base-input>
      </div>
      <div class="col-12 col-md-4">
        <base-input :label="$t('fields.date_out')">
          <el-date-picker
              v-model="filters.dateOutRange"
              type="daterange"
              align="right"
              unlink-panels
              format="dd/MM/yyyy"
              value-format="yyyy-MM-dd"
              range-separator="-"
              :start-placeholder="$t('fields.date_start')"
              :end-placeholder="$t('fields.date_end')"
              :picker-options="pickerOptions"
          />
        </base-input>
      </div>
      <div class="col-12 col-md-4">
        <base-input :label="$t('fields.confirmed_date')">
          <el-date-picker
              v-model="filters.confirmedDateRange"
              type="daterange"
              align="right"
              unlink-panels
              format="dd/MM/yyyy"
              value-format="yyyy-MM-dd"
              range-separator="-"
              :start-placeholder="$t('fields.date_start')"
              :end-placeholder="$t('fields.date_end')"
              :picker-options="pickerOptions"
          />
        </base-input>
      </div>
      <div class="col-12 col-md-4">
        <base-input :label="$t('fields.competence_date')">
          <el-date-picker
              v-model="filters.competenceDateRange"
              type="daterange"
              align="right"
              unlink-panels
              format="dd/MM/yyyy"
              value-format="yyyy-MM-dd"
              range-separator="-"
              :start-placeholder="$t('fields.date_start')"
              :end-placeholder="$t('fields.date_end')"
              :picker-options="pickerOptions"
          />
        </base-input>
      </div>
    </template>
  </octo-table>
</template>

<script>
import OctoTable from "@/components/octo-table/OctoTable";
import OctoIcon from "@/components/octo-icon/OctoIcon";
import {endpoints} from "@/routes/endpoints";
import Badge from "@/components/Badge";
import {Select, Option, DatePicker} from "element-ui";
import {mapGetters} from "vuex";
import DealStatuses from "@/pages/Deals/resources/dealStatuses";
import LabelThemeComponent from "@/components/LabelThemeComponent";
import ListGroupItemComponent from "@/components/ListGroupItemComponent";
import {schedulerService} from "@/util/scheduler-service";
import DealAccountingStatus from "@/pages/Deals/resources/dealAccountingStatus";
import DealPaymentTypes from "@/pages/Deals/resources/dealPaymentTypes";

export default {
  name: "PostSellingArchiveDatatable",
  components: {
    ListGroupItemComponent,
    LabelThemeComponent,
    Badge,
    OctoIcon,
    OctoTable,
    [Select.name]: Select,
    [Option.name]: Option,
    [DatePicker.name]: DatePicker,
  },
  data() {
    return {
      endpoints: endpoints,
      name: 'PostSellingArchiveDatatable',
      filters: {},
      fields: [
        {prop: 'registry', label: 'lead', slot: true, fixed: true, width: 250},
        {prop: 'sales', label: 'sales', slot: true, width: 200},
        {prop: 'operator', label: 'operator', slot: true, width: 200},
        {prop: 'status', label: 'deal_status', slot: true, align: 'center', minWidth: 180},
        {prop: 'accounting_status', label: 'accounting_status', slot: true, align: 'center', width: 150},
        {prop: 'amount', label: 'amount', slot: true, align: 'center', width: 150},
        {prop: 'payment_type', label: 'payment_type', slot: true, align: 'center', width: 200},
        {prop: 'date', label: 'date', slot: true, align: 'center', width: 200},
        {prop: 'confirmed_date', label: 'confirmed_date', slot: true, align: 'center', width: 150},
        {prop: 'competence_date', label: 'competence_date', slot: true, align: 'center', width: 150},
        {prop: 'actions', label: 'actions', width: 60, slot: true, fixed: 'right'},
      ],
      statuses: [
        'closed',
        'accounting_rejected',
        'promoted',
      ],
      paymentOptions: this.$_.map(DealPaymentTypes, (item) => item.value),
      specialOptions: [
        {label: this.$t('deal.show_expired_only'), value: 'show_expired_only'},
        {label: this.$t('deal.show_balanced_only'), value: 'show_balanced_only'},
        {label: this.$t('deal.show_not_balanced_only'), value: 'show_not_balanced_only'},
      ],
      schedulerService: schedulerService,
      dealStatuses: DealStatuses,
      dealAccountingStatuses: DealAccountingStatus,
    }
  },
  computed: {
    ...mapGetters({
      users: 'common/users',
      sales: 'common/sales',
      operators: 'common/operators',
      getFilters: 'common/datatableFilters',
      courses: 'common/allCourses'
    }),

    pickerOptions() {
      return {firstDayOfWeek: 1};
    }
  },
  beforeMount() {
    this.filters = this.getFilters(this.name);
  },
  methods: {
  },
}
</script>

<style scoped>

</style>
